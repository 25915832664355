<template>
  <div id="login" class="fill-height">
    <v-container fluid class="fill-height">
      <v-row class="fill-height pb-10" style="overflow-y:auto;">
        <v-col cols="12" lg="8" offset-lg="2" class=" mt-10">
          <v-card shaped>
            <v-row>
              <v-col cols="12" md="6" >
                <v-card-title>
                  <v-img class="mr-2" max-height="40" max-width="40" contain src="@/assets/RIO.png"></v-img>
                  <h2 style="word-break: normal;">RPG Inventory Organizer <v-chip dense>Beta</v-chip></h2>
                </v-card-title>
                <v-card-text>
                  <p>
                    This is a simple system to allow Game Masters and players
                    of table-top roleplaying games to collaboratively track their
                    inventory across characters and containers.
                  </p>
                  <v-divider class="my-5"></v-divider>
                  <p>
                    Being developed by Ethan Heming<br>
                    Copyright ©{{ new Date().getFullYear() }}
                  </p>
                  <p>Please enjoy and send feedback or suggestions to <span>hem</span><span>ing</span><span>.e</span><span>@gma</span><span>il.com</span></p>
                </v-card-text>
              </v-col>
              <v-col cols="12" md="6" >
                <v-card-text>
                  <div v-if="showLoginForm" >
                    <v-form @submit.prevent>
                      <v-text-field
                      v-model.trim="loginForm.email"
                      :rules="emailRules"
                      label="E-mail"
                      required
                      ></v-text-field>

                      <v-text-field
                      v-model.trim="loginForm.password"
                      :rules="passwordRules"
                      type="password"
                      label="Password"
                      required
                      @keyup.enter="login()"
                      ></v-text-field>
                      <v-btn x-large block @click="login()" class="button">Log In</v-btn>

                    </v-form>
                    <div class="mt-4">
                      <v-btn class="mr-2 mt-1" @click="togglePasswordReset()">Forgot Password</v-btn>
                      <v-btn class="mr-2 mt-1" @click="toggleForm()">Create an Account</v-btn>
                    </div>
                  </div>

                  <div v-else>
                    <v-form @submit.prevent>
                      <v-text-field
                      v-model.trim="signupForm.name"
                      :rules="nameRules"
                      label="Name"
                      required
                      ></v-text-field>

                      <v-text-field
                      v-model.trim="signupForm.email"
                      :rules="emailRules"
                      label="E-mail"
                      required
                      ></v-text-field>

                      <v-text-field
                      v-model.trim="signupForm.password"
                      :rules="passwordRules"
                      type="password"
                      label="Password"
                      required
                      ></v-text-field>

                      <v-btn x-large block @click="signup()" class="button">Sign Up</v-btn>

                    </v-form>
                    <div class="mt-4">
                      <v-btn class="mr-2 mt-1" @click="toggleForm()">Back to Log In</v-btn>
                    </div>
                  </div>
                </v-card-text>

              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="passwordReset.showPasswordReset" max-width="500px">
      <v-card><v-card-text>
        <h2 class="py-5">Reset Password</h2>
        <div v-if="!passwordReset.showSuccess">
          <p>Enter your email to reset your password</p>
          <v-text-field
          v-model.trim="passwordReset.email"
          :rules="emailRules"
          label="Reset Email"
          required
          ></v-text-field>
          <p v-if="passwordReset.errorMsg !== ''" class="error">{{ passwordReset.errorMsg }}</p>
          <v-btn block @click="resetPassword()" class="button">Reset</v-btn>
        </div>
        <p v-else>Success! Check your email for a reset link.</p>
      </v-card-text></v-card>
    </v-dialog>
    <v-dialog v-model="loginError" max-width="500px">
      <v-card><v-card-text>
        <h2 class="py-5">Login Failure</h2>
        <p>{{loginIssues}}</p>
      </v-card-text></v-card>
    </v-dialog>
    <v-dialog v-model="signupError" max-width="500px">
      <v-card><v-card-text>
        <h2 class="py-5">Signup Failure</h2>
        <p>{{signupIssues}}</p>
      </v-card-text></v-card>
    </v-dialog>
  </div>
</template>

<script>
import { auth } from "@/firebase";
export default {
  data() {
    return {
      loginForm: {
        email: "",
        password: ""
      },
      loginError: false,
      loginIssues: '',
      signupForm: {
        name: "",
        email: "",
        password: ""
      },
      signupError: false,
      signupIssues: '',
      nameRules: [
        v => !!v || 'Name is required',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid'
      ],
      passwordRules: [
        v => !!v || 'Password is required',
      ],
      showLoginForm: true,
      passwordReset: {
        showPasswordReset: false,
        email: "",
        showSuccess: false,
        errorMsg: ""
      }

    };
  },
  methods: {
    toggleForm() {
      this.showLoginForm = !this.showLoginForm;
    },
    togglePasswordReset() {
      this.passwordReset.showSuccess = false;
      this.passwordReset.email = "";
      this.passwordReset.showPasswordReset = !this.passwordReset.showPasswordReset;
    },
    async login() {
      try {
        const { user } = await auth.signInWithEmailAndPassword(
          this.loginForm.email,
          this.loginForm.password
        )
        this.$store.dispatch("login", user);
      } catch (errors) {
        this.loginError = true
        this.loginIssues = errors
      }

    },
    async signup() {
      try {
        const { user } = await auth.createUserWithEmailAndPassword(
          this.signupForm.email,
          this.signupForm.password
        )
        this.$store.dispatch("signup", {
          user: user,
          name: this.signupForm.name,
        });
      } catch (errors) {
        this.signupError = true
        this.signupIssues = errors
      }
    },
    async resetPassword() {
      this.passwordReset.errorMsg = "";
      try {
        await auth.sendPasswordResetEmail(this.passwordReset.email);
        this.passwordReset.showSuccess = true;
      } catch (err) {
        this.passwordReset.errorMsg = err.message;
      }
    }
  }
};
</script>
